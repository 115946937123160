import React, { Component } from 'react';

import AdhList from './Adhs';
//const mySql=require('../mySql/sqlServer');

var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');
const fs = require('browserify-fs'); 

class AdhAdd extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',data:[]};
		this.getUserRights=this.getUserRights.bind(this);
		this._addAdh=this._addAdh.bind(this);
		this.updateField=this.updateField.bind(this);
		this.createArray=this.createArray.bind(this);
		this.createDocFolder=this.createDocFolder.bind(this);	
		this.shapeBirthDate=this.shapeBirthDate.bind(this);
	}
	
	createArray()
	{
		console.log("arr");
		let f=[];
		let n=16;
		for(var i=0;i<n;i++)
		{
			f[i]=document.getElementById(i).value;
			console.log(i+" : "+f[i]);
		}
        this.setState({data:f});		
		console.log("state :"+this.state.data);
	}
   _addAdh()
	{
		console.log("add g");
	//	
	let r=this.state.data;
		let params=guiUtils.arrayToString(r);
		console.log("pp"+params);
		
		let str=config.sqlServerAddress()+'/gRaw/addAdh/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\","+params;
		console.log("str : "+str);
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
		//	this.createDocFolder(parsed[0].id);
		alert("Adhérent ajouté avec succès");
      		window.location=config.serverAddress()+"/adh";
		});
	}
		
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({userRank:parsed[0].rank});
			this.createArray();
		});
			
	}
	createDocFolder(adhId)
	{
		fs.mkdir('home/websites/crematistes/adh_docs/'+adhId, function() 
		{
	
			console.log(adhId);
		
	    });

/*
		var dir ='./adh_docs/'+adhId; 
		const path = mkdir(dir);
		console.log(path);
		 * */
	}
	componentDidMount()
	{
	    this.getUserRights();
	}
	shapeBirthDate(date)
	{
		let arr=date.split("/",3);
		let ret= arr[2]+"-"+arr[1]+"-"+arr[0];
		console.log("date :"+ret);
		return ret;
	}
	updateField(event)
	{
		console.log("id"+event.target.id);
		let r=this.state.data;
		let texte=event.target.value;
		console.log("tt"+texte);
	//	if(event.target.id==3&&texte.length==10) texte=guiUtils.reshapeDateToSql(texte);
		console.log("tt2"+texte);
		r[event.target.id]=texte;
		
		this.setState({data:r});
		console.log(this.state.data);
	}
	
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			
			return (
			<div id="addAdh"><br/><div class='text'>Création de Fiche Adhérent</div><br/>
			<form onSubmit={()=>this._addAdh()}>
			<table>
			<tbody>
			<tr>
			<td>Nom </td>
			<td><input id="0" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Nom Jeune Fille </td>
			<td><input id="1" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Prénom </td>
			<td><input id="2" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Date de Naissance </td>
			<td><input id="3" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Adresse </td>
			<td><input id="4" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Complément Adresse </td>
			<td><input id="5" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Code Postal </td>
			<td><input id="6" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Ville </td>
			<td><input id="7"  type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Téléphone </td>
			<td><input id="8" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>Courriel </td>
			<td><input id="9" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>T </td>
			<td><input id="10" type="text" onChange={this.updateField} /></td>
			</tr>
			<tr>
			<td>D </td>
			<td><input id="11" type="text" onChange={this.updateField} /></td>
			 </tr>
			 <tr>
			<td>M </td>
			<td><input id="12" type="text" onChange={this.updateField}/></td>
			 </tr>
			 <tr>
			<td>S.C </td>
			<td><input id="13" type="text" onChange={this.updateField} /></td>
			 </tr>
			 <tr>
			<td>Responsable </td>
			<td><input id="14" type="text" onChange={this.updateField} /></td>
			 </tr>
			 <tr>
			<td>Destination Cendres </td>
			<td><input id="15" type="text" onChange={this.updateField} /></td>
			 </tr>
			</tbody>
            </table>	
           <input id="userAddButton" type="button" value="Ajouter Adhérent" onClick={()=>this._addAdh()}/>
				
			</form>
			</div>);			
		}
		else return (<div>Attendre</div>);	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhAdd;