import React, { Component } from 'react';

var request=require('request');
var config=require('../config');
var guiUtils=require('../ApsHtmlGuiUtils');

class OldAdhList extends Component
{
	constructor(props){
		super(props);
		this.state={adhTable:[],updateMode:false,activeAdh:''};
		this.activateAdh=this.activateAdh.bind(this);
		this.getAdhTable=this.getAdhTable.bind(this);
		this.updateField=this.updateField.bind(this);
		this.updateAdh=this.updateAdh.bind(this);
		this.viewAdhDocs=this.viewAdhDocs.bind(this);
		this.viewAdhPayments=this.viewAdhPayments.bind(this);
this.renderDepositButton=this.renderDepositButton.bind(this);
this.renderFileButton=this.renderFileButton.bind(this);
	}
	
	updateField(event)
	{
		
	}
	
	updateAdh(memberId)
	{
		
		window.location=config.serverAddress()+"/adhUpdate?adhId="+memberId;
			
	}
	renderFileButton(adhId,t)
	{
		if(t=="T") return (<div><input type='button' value='Fichiers' onClick={()=>this.viewAdhDocs(adhId)} /></div>);
		else return (<div />);
	}
	renderDepositButton(adhId,d)
	{
		if(d=="D") return (<div><input type='button' value='Dépôt' onClick={()=>this.viewAdhPayments(adhId)} /></div>);
		else return (<div />);
	}
	render()
	{
				return (<div id='AdhList'><div class='text'> {this.props.title}</div>
		<table> 
		<thead>
		<tr>
		<th>Modifier</th>
		<th>Archiver</th>
		<th>Cotisations</th>
		<th>Dépôt</th>
		<th>Fichiers</th>
		<th>Nom</th>
		<th>Nom Jeune Fille</th>
		<th>Prénom</th>
		
		<th>Naissance</th>
		<th>Addresse</th>
		<th>Complément Adresse</th>
		<th>Code Postal</th>
		<th>Ville</th>
		<th>Téléphone</th>
		<th>Courriel</th>
		<th>T</th>
		<th>D</th>
		<th>M</th>
		<th>S.C</th>
		<th>Responsable Testament</th>
		<th>Destination Cendres</th>
		</tr>
		</thead>
		<tbody>
		{this.state.adhTable.map(member =>
		
		<tr key={member.id}>
		<td><input type='button' value='Modifier' onClick={()=>this.updateAdh(member.id)} /></td>
		<td><input type='button' value='Activer' onClick={()=>this.activateAdh(member.id)} /></td>
		<td><input type='button' value='Cotisations' onClick={()=>this.viewCotis(member.id)} /></td>
		<td>{this.renderDepositButton(member.id,member.D)}</td>
		<td>{this.renderFileButton(member.id,member.T)}</td>
		<td>{member.nom} </td>
		<td>{member.nom_jeune_fille} </td>
		<td>{member.prenom} </td>
		
		<td>{member.naissance} </td>
		<td>{member.adresse} </td>
		<td>{member.adresse2} </td>
		<td>{member.code_postal} </td>
		<td>{member.ville} </td>
		<td>{member.tel} </td>
		<td>{member.mail} </td>
		<td>{member.T} </td>
		<td>{member.D} </td>
		<td>{member.M} </td>
		<td>{member.SC} </td>
		<td>{member.resp} </td>
		<td>{member.dest} </td>
		
		</tr>
		
		)}
		</tbody>
		</table>
		</div>);
	}
	viewCotis(adhId)
	{
		window.location=config.serverAddress()+"/adhCotisMan?adhId="+adhId;
	}
	viewAdhDocs(adhId)
	{
		window.location=config.serverAddress()+"/adhDocsMan?adhId="+adhId;
	}
	viewAdhPayments(adhId)
	{
		window.location=config.serverAddress()+"/adhPaymentsMan?adhId="+adhId;
	}
	componentDidMount()
	{
	    this.getAdhTable();
	}
	
	activateAdh(adhId)
	{
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/activateAdh/'			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"','"+adhId+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				
			}).then(function(data){
				alert("Adhérent activé avec succès");
				self.getAdhTable();
				
				//window.location=config.serverAddress()+"/adh";
			}).catch(err=>{console.log('caught',err);
			});	
	}
	getAdhTable()
    {
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/getOldAdhList/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				self.setState({adhTable:data});
			//	self.forceUpdate();
			}).catch(err=>{console.log('caught',err);
			});		  	
    }		
}

export default OldAdhList;