import React, { Component } from 'react';
import FileUpload from '../fileUpload';
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');


class AdhDocsMan extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',adhId:'0',data:[],adhDetails:[],path:''};
		this.getUserRights=this.getUserRights.bind(this);
	    this.getAdhId=this.getAdhId.bind(this);
		this.getAdhFiles=this.getAdhFiles.bind(this);
		this.deleteFile=this.deleteFile.bind(this);
		this.viewFile=this.viewFile.bind(this);
		this.addFile=this.addFile.bind(this);
		this.getDetails=this.getDetails.bind(this);
	}
	deleteFile(fileName)
	{
		let str=config.sqlServerAddress()+'/gRaw/unlinkFile/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\""+fileName+"\"";
		console.log(str);
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.getAdhFiles();
		});
	}
	viewFile(fileName)
	{
		window.location=config.serverAddress()+"/adh_docs/"+fileName;
	}
	addFile()
	{
		window.location=config.serverAddress()+"/uploadFile?adhId="+this.state.adhId;
	}
	getAdhDocs()
	{
		
	}
	getAdhFiles()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhFiles/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({data:parsed});
			
		});
	}
	
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({userRank:parsed[0].rank});
			this.getAdhId();
		});
	}
	getAdhId()
	{
		this.setState({adhId:window.location.search.replace("?adhId=","")});
		this.getAdhFiles();
		this.getDetails();
	}
	componentDidMount()
	{
	    this.getUserRights();
	}
	
	getDetails()
	{
		let ad=this.props.adhId;
		if(ad!=0)
		{
			 let str=config.sqlServerAddress()+'/gRaw/getAdhDetails/'
		    +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
        console.log(str);
		    request.get(str,(err,res,b)=>
			{
				 var parsed=JSON.parse(b);
			     this.setState({adhDetails:parsed[0]});
	               }); 
		}
		
    } 
	render()
	{
		let ad=this.state.adhDetails;
		return (<div id="adhDocsMan">
		<br/>
		<div class='text'>Adhérent : {ad.nom} {ad.prenom}</div>
		<br/>
		<FileUpload adhId={this.state.adhId} />
		<br />
		Liste des documents
		<table> 
		<thead>
		<tr>
		<th>Nom du fichier</th>
		
		</tr>
		</thead>
		<tbody>
		{this.state.data.map(member =>
		
		<tr key={member.id}>
		<td>{member.file_name} </td>
		
		<td><input type='button' value='Ouvrir' onClick={()=>this.viewFile(member.file_name)} /></td>
		<td><input type='button' value='Effacer' onClick={()=>this.deleteFile(member.file_name)} /></td>
		</tr>)}
		</tbody>
		</table>
		</div>);
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhDocsMan;