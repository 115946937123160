import React, { Component } from 'react';
var request=require('request');
var config=require('../config');
var guiUtils=require('../ApsHtmlGuiUtils');

class UserList extends Component
{
	constructor(props){
		super(props);
		this.state={usersTable:[]};
		
		this.getUsersTable=this.getUsersTable.bind(this);
		this.deleteUser=this.deleteUser.bind(this);
		this.setUserRank=this.setUserRank.bind(this);

	}
	deleteUser(userId)
	{
		console.log(userId);
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/deleteUser/'			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"','"+userId+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				
			}).then(function(data){
				window.location=config.serverAddress()+"/admin";
			}).catch(err=>{console.log('caught',err);
			});	
	}
	setUserRank(userId,newRank)
	{
		console.log(userId);
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/setUserRank/'
			+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"','"+userId+"','"+newRank+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				
			//	return response.json();
			}).then(function(data){
				window.location=config.serverAddress()+"/admin";
			}).catch(err=>{console.log('caught',err);
			});	
	}
	render()
	{
		if(this.props.rank=="0")
		{
		return (<div><div class='text'> {this.props.title} </div>
		<table> 
		<thead>
		<tr><th class='text'>Nom</th></tr>
		</thead>
		{this.state.usersTable.map(member =>
		<tr key={member.Nom}>
		<td class='text'>{member.Nom}</td>
		
		<td><input type='button' value='Passer Administrateur' onClick={()=>this.setUserRank(member.id,"2")} /></td>
		<td><input type='button' value='Supprimer' onClick={()=>this.deleteUser(member.id)} /></td>
		
		
		</tr>
		)}
		</table>
		</div>);
		
		}
		else if(this.props.rank=="2")
		{
		return (<div><div class='text'>{this.props.title}</div>
		<table> 
		<thead>
		<tr><th class='text'>Nom</th></tr>
		</thead>
		{this.state.usersTable.map(member =>
		<tr key={member.Nom}>
		<td class='text'>{member.Nom}</td>
		<td><input type='button' value='Passer Inscrit' onClick={()=>this.setUserRank(member.id,"0")} /></td>
		<td><input type='button' value='Supprimer' onClick={()=>this.deleteUser(member.id)} /></td>
		
		</tr>
		)}
		</table>
		</div>);
		
		}
	//	return (<div>{this.props.title} <Table data={this.state.usersTable}/></div>);
	}
	componentDidMount()
	{
	    this.getUsersTable();
	}
	getUsersTable()
    {
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/getUsersList/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"','"+this.props.rank+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				self.setState({usersTable:data});
			}).catch(err=>{console.log('caught',err);
			});		  	
    }		
}

export default UserList;