import React,{Component} from 'react'; 
import axios from 'axios'; 
var config=require('./config');
var request=require('request');
const fileUpload = require('express-fileupload');
const path=require('path');
const ExcelJS = require('exceljs');
const csv=require('csvtojson');
var guiUtils=require('./ApsHtmlGuiUtils');
class AdhExcelUpload extends Component { 
	
	constructor(props){
		super(props);
		this.state={selectedFile: null, progress:''};
	this.importFile=this.importFile.bind(this);
	this.setFile=this.setFile.bind(this);
	this.parseFile=this.parseFile.bind(this);
	this.parseCsv=this.parseCsv.bind(this);
	this.onError=this.onError.bind(this);
	this.onComplete=this.onComplete.bind(this);
	this.importJsonLine=this.importJsonLine.bind(this);
	}
	
	setFile(files)
	{
		let f=files[0];
		console.log(f);
		  let ad=this.props.adhId;
	    console.log("f: "+f.name);
		this.setState({selectedFile:f});
      //	this.upFile(f);
	 //   this.linkFile(f);
	}
	onError()
	{
		
	}
	onComplete()
	{
		
	}
	importJsonLine(json)
	{
		let tab=[];
		
		console.log(json);
		tab[0]=json.NOM;
		tab[1]=json["NOM JEUNE FILLE"];
		tab[2]=json.PRENOM;
		
	//	tab[3]=json.NAISSANCE;
		let _date=json.NAISSANCE;
		
	//	let _date=guiUtils.reshapeDateToSql(json.NAISSANCE);
		console.log("date:"+_date);
		tab[3]=_date;
		
		
		tab[4]=json.ADRESSE;
		tab[5]=json["COMP ADRESSE"];
		tab[6]=json["CODE POSTAL"];
		tab[7]=json.VILLE;
		tab[8]=json.TEL;
		tab[9]=json["email/règl. Cotis."];
		tab[10]=json.T;
		tab[11]=json.D;
		tab[12]=json.M;
		tab[13]=json["S. C."];
		tab[14]=json["RESPONSABLE TESTAMENT"];
		tab[15]=json["DESTINATION CENDRES"];
		let params=guiUtils.arrayToString(tab);
		console.log("pp"+params);
		
		let str=config.sqlServerAddress()+'/gRaw/addAdh/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\","+params;
		console.log("str : "+str);
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
		
		});
	}
	
  parseCsv()
  {
	  let lineNumber=0;
	//  let _path='../xlsxAdh/adh.xlsx';
	  const csvFilePath=config.serverAddress()+'/csv/adh.csv';
console.log(csvFilePath);

	csv()
.fromStream(request.get(csvFilePath))
.subscribe((json)=>{
	this.importJsonLine(json);
	
    // process the json line in synchronous.
},this.onError, this.onComplete);



/*
      csv().fromStream(request.get(csvFilePath))
     .subscribe((json)=>{
      return new Promise((resolve,reject)=>{
		  console.log(json);
        // long operation for each json e.g. transform / write into database.
      })
      });*/
  }
   importFile()
   {
	   let self=this;
	let f=this.state.selectedFile;
//	self.parseFile();
	   
	const formData = new FormData(); 
      let ad=this.props.adhId;
	  console.log("f: "+f.name);
      // Update the formData object 
      formData.append( 
        "file", 
        f,
		'adh.csv'
      ); 
	   
     
      // Details of the uploaded file 
   //   console.log(formData); 
	    
      axios.post(config.serverAddress()+"/fzxdAdh", formData,{
		  onUploadProgress:(ProgressEvent)=>{
			  let _progress =Math.round(
			  ProgressEvent.loaded/ProgressEvent.total*100)+'%';
			  this.setState({progress:_progress});
			 // setProgress(progress);
		  }
	  }).then(function(response)
	  {
		  self.parseCsv();
	  });  
//	 
   }
   parseFile()
   {
	//   let f=this.state.selectedFile;
	   let _path='../xlsxAdh/adh.xlsx';
	   console.log("p :"+_path);
	   const workbook = new ExcelJS.Workbook();
	   workbook.xlsx.read(_path);
	   console.log(workbook);
	   const worksheet = workbook.worksheets[0];
	   const row = worksheet.getRow(0);
	 console.log(row);
   }
    render() { 
      return ( 
       <div id="importAdhForm">
		
		<input class ='text'type="file" name="sampleFile"  onChange={(e)=>this.setFile(e.target.files)} />
            <input class ='text' type="button" value="Importer"  onClick={()=>this.importFile()} />
			
		<br/><br/>	<div class='tip'>Le fichier doit être au format CSV, les champs du fichier importé doivent être séparés par des virgules et entourés chacun de guillemets doubles (touche 3 du clavier) </div>
      	
        </div> 
      ); 
    } 
  } 
  
  export default AdhExcelUpload; 
