import React, { Component } from 'react';
import ReactDOM from 'react-dom';

var request=require('request');
var config=require('./../config');
var guiUtils=require('./../ApsHtmlGuiUtils');
var bcrypt=require('bcryptjs');
var sha256=require("crypto-js/sha256");

async function waitAndHome(ms)
{
	
//	await guiUtils.sleep(ms);
this.props.history.push("/");	
}


class UserLogin extends Component{
	constructor(props){
		super(props);
this.state={login:'guest',hash:'',pwd:'',userRank:'',connRez:[],connStr:''};
		this.handleClick=this.handleClick.bind(this);
		this.changeLogin=this.changeLogin.bind(this);
		this.changePwd=this.changePwd.bind(this);
		
	}
changeLogin(event)
{
	this.setState({login:event.target.value});
	
}
changePwd(event)
{
 	this.setState({pwd: sha256(event.target.value)});
}

	handleClick(){
		
		sessionStorage.setItem("u",this.state.login);
		sessionStorage.setItem("p",this.state.pwd);
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/test_User_Hash/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				self.setState({connRez:data});
				let strRes=data[0].result;
				if(strRes=="1") {
					self.setState({connStr:"Connection active"});
					self.setState({userRank:data[0].rank});
					
					}
				else self.setState({connStr:"Paramètres incorrects"});
				
				window.location=config.serverAddress();
				
			}).catch(err=>{console.log('caught',err);
			});		
			
						 
		
	}
	
	componentDidMount()
	{
		
	}
	render()
	{
		
		return (<div id="userLoginForm">
		 <div class='text' id="emailL">Nom Utilisateur *</div>
		<input class='field' id="emailField" type="text" onChange={this.changeLogin}></input>
		<div class='text' id="userPwdL2">Mot de passe *</div>
		<input class='field' id="userPwdField" type="password" onChange={this.changePwd}></input>
		
		<input id="userLogButton" type="button" value="Connecter" onClick={this.handleClick} />
		<div class='text' id="userLogResult">{this.state.connStr}</div>
		   	</div>
		);
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default UserLogin;