import React, { Component } from 'react';
import NiceInputPassword from 'react-nice-input-password';
import ReactDOM from 'react-dom';

//const mySql=require('../mySql/sqlServer');
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');
var md5=require("md5");
var sha256=require("crypto-js/sha256");
//var bcrypt=require('bcryptjs');
//var sql=require("../sqlServer");


class UserAdd extends Component{
	constructor(props){
		super(props);
		this.state={connRez:[]};
		this.createAccount=this.createAccount.bind(this);
		
	}

	createAccount(){
		
		if(document.getElementById("userPwdField").value==document.getElementById("userPwdField2").value)
		{
			var _u="none";
			if(document.getElementById("loginField").value!="")
			{
				_u=document.getElementById("loginField").value;
				_u=_u.replace("'","").replace("'","");
			}
			var _p="none";
			if(document.getElementById("userPwdField").value!="")
			{
				_p=sha256(document.getElementById("userPwdField").value);
			}
			
			
			sessionStorage.setItem("u",_u);
			sessionStorage.setItem("p",_p);
			
			sessionStorage.setItem("rank",0);
			
			let self=this;
		let str=config.sqlServerAddress()+'/gRaw/addUser/'			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				
				return response.json();
			}).then(function(data){
				self.setState({connRez:data[0].result});
			}).catch(err=>{console.log('caught',err);
			});	
				 
        }	
		
		else alert("Les mots de passe ne correspondent pas");
			console.log("calling");
	}
	componentDidMount()
	{
	
	}
	
	render()
	{
		
		
		return (<div id="userAddForm">
		 <div class='text' id="emailL">Nom Utilisateur *</div>
		<input class='field' id="loginField" type="text"></input>
		<div class='text' id="userPwdL2">Mot de passe *</div>
		<input class='field' id="userPwdField" type="password" />
		<div class='text' id="userPwdL">Mot de passe (pour v&#233;rification) *</div>
		<input class='field' id="userPwdField2" type="password" />
		<br />
		<input class='field' id="userAddButton" type="button" value="Valider" onClick={this.createAccount} />
		<div class='text' id="userAddResult">{this.state.connRez}</div>
	   	
		</div>
		
		);
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default UserAdd;