
import axios from 'axios'; 
  
import React,{Component} from 'react'; 
var config=require('./config');
var request=require('request');
const fileUpload = require('express-fileupload');
const path=require('path');

class FileUpload extends Component { 
	
	constructor(props){
		super(props);
		this.state={selectedFile: null, progress:''};
	this.upFile=this.upFile.bind(this);
	this.sendFile=this.sendFile.bind(this);
	this.setFile=this.setFile.bind(this);
	this.linkFile=this.linkFile.bind(this);
	}
	sendFile(file)
	{
		console.log("ddd");
	    let fN=this.state.adhId;
	    console.log("id :"+fN);
	
	    if(fN>0)
	    {
	        let _path=path.join(__dirname,'build/adh_docs/'+file.name);
          // Use the mv() method to place the file somewhere on your server
          file.mv(_path, function(err) 
		  {
              if (err)	{   }
	      })
		}
	}
	setFile(files)
	{
		let f=files[0];
		console.log(f);
		  let ad=this.props.adhId;
	    console.log("f: "+f.name);
		this.setState({selectedFile:f});
      //	this.upFile(f);
	 //   this.linkFile(f);
	}
   linkFile()
   {
	   let f=this.state.selectedFile;
	    let str=config.sqlServerAddress()+'/gRaw/linkFile/'
		    +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.props.adhId+"\",\""+f.name+"\"";
        console.log(str);
		    request.get(str,(err,res,b)=>
			{
			window.location=config.serverAddress()+"/adhDocsMan?adhId="+this.props.adhId;
	               }); 
   } 
   upFile()
   {
	   let self=this;
	let f=this.state.selectedFile;
	const formData = new FormData(); 
      let ad=this.props.adhId;
	  console.log("f: "+f.name);
      // Update the formData object 
      formData.append( 
        "file", 
        f,
		f.name
      ); 
	  
     
      // Details of the uploaded file 
   //   console.log(formData); 
	    
      axios.post(config.serverAddress()+"/fzxd?adhId="+this.props.adhId, formData,{
		  onUploadProgress:(ProgressEvent)=>{
			  let _progress =Math.round(
			  ProgressEvent.loaded/ProgressEvent.total*100)+'%';
			  this.setState({progress:_progress});
			 // setProgress(progress);
		  }
	  }).then(function(response)
	  {
		  self.linkFile();
	  });  
//	 
   }
    render() { 
      return ( 
        <div id='fileUpload'> <div class='text'>Envoi Nouveau fichier</div>
		<input class='text' type="file" name="sampleFile"  onChange={(e)=>this.setFile(e.target.files)}/>
            <input class='text' type="button" value="Envoyer"  onClick={()=>this.upFile()}/>
			<div class='text'>Chargement : {this.state.progress}</div>
        </div> 
      ); 
	  /*return ( 
        <div> 
           <form ref='uploadForm' 
      id='uploadForm' 
	  action={road}
      method='post' 
      encType="multipart/form-data">
        <input type="file" name="sampleFile" />
        <input type='submit' value='Envoyer' onClick={()=>this.upFile()}/>
	  </form>  
        </div> 
      ); */
    } 
  } 
  
  export default FileUpload; 
