import React,{Component} from 'react'; 
import axios from 'axios'; 
var config=require('../config');
var request=require('request');
const fileUpload = require('express-fileupload');
const path=require('path');
const ExcelJS = require('exceljs');
const csv=require('csvtojson');
var guiUtils=require('../ApsHtmlGuiUtils');
class DepositCsvUpload extends Component { 
	
	constructor(props){
		super(props);
		this.state={selectedFile: null, progress:''};
	this.importFile=this.importFile.bind(this);
	this.setFile=this.setFile.bind(this);
	this.parseFile=this.parseFile.bind(this);
	this.parseCsv=this.parseCsv.bind(this);
	this.onError=this.onError.bind(this);
	this.onComplete=this.onComplete.bind(this);
	this.importJsonLine=this.importJsonLine.bind(this);
	this.importDeposit=this.importDeposit.bind(this);
	this.importActus=this.importActus.bind(this);
	this.importRemboursement=this.importRemboursement.bind(this);
	this.importReserve=this.importReserve.bind(this);
	}
	
	setFile(files)
	{
		let f=files[0];
		console.log(f);
		  let ad=this.props.adhId;
	    console.log("f: "+f.name);
		this.setState({selectedFile:f});
      //	this.upFile(f);
	 //   this.linkFile(f);
	}
	onError()
	{
		
	}
	onComplete()
	{
		
	}
	importDeposit(adhId,json)
	{
		let d=json["VERSEMENT EURO"];
		if(d!=undefined)
		{
		let dep=guiUtils.cleanString(json["VERSEMENT EURO"],4,7);
	//	let dep=json.VERSEMENT.replace(",",".").replace(" ","").replace("0 ","0").replace("1 ","1").replace("2 ","2").replace("3 ","3").replace("4 ","4").replace("5 ","5").replace("6 ","6").replace("7 ","7").replace("8 ","8").replace("9 ","9").replace("€","");
		console.log("dep :"+dep);
		let str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		adhId+"\",\""+"0"+"-01-01\",\"4\",\""+(dep*6.55957)+"\",\""+dep+"\"";
		
		
		request.get(str,(err,res,body)=>{
			console.log("body :"+body);
			 
			 this.importActus(adhId,json);
			 this.importRemboursement(adhId,json);
	//		 this.importReserve(adhId,json);		
		});
		}
		
		
	}
	importReserve(adhId,json)
	{
		let rbt=json.RESERVE;
		let r=-guiUtils.cleanString(rbt,4,7);
		let str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		adhId+"\",\""+"0"+"-01-01\",\"8\",\""+(r*6.55957)+"\",\""+r+"\"";
		
		
		request.get(str,(err,res,body)=>{
			
			 
			
		
		});
	}
	importRemboursement(adhId,json)
	{
		let rbt=json.REMBOURST;
		let r=-guiUtils.cleanString(rbt,4,7);
		let str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		adhId+"\",\""+"0"+"-01-01\",\"6\",\""+(r*6.55957)+"\",\""+r+"\"";
		
		
		request.get(str,(err,res,body)=>{
			
			 
			
		
		});
	}
	importActus(adhId,json)
	{
		let firstYear=1989;
		for(var i=firstYear;i<2020;i++)
		{
			let strYear="\"ACTU."+i+"\"";
			console.log("y:"+strYear);
			let val=json.ACTU[i];
			let endVal=guiUtils.cleanString(val,4,7);
			console.log("src val :"+val);
				
			
			
			let str="";
			if(i<=2000)
				{
					str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
					+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		        adhId+"\",\""+i+"-01-01\",\"5\",\""+(endVal)+"\",\""+endVal/6.55957+"\"";	
				}
		        
			else
				{
				str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		        +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		        adhId+"\",\""+i+"-01-01\",\"5\",\""+(endVal*6.55957)+"\",\""+endVal+"\"";	
				
			}
			
			if(endVal)
			{
				console.log("str : "+str);
				request.get(str,(err,res,body)=>
				{	
		
		        });
			}
		}
		console.log("import actus");
	}
	importJsonLine(json)
	{
		//si il y a un nom
		let nom=json.NOM;
		if(nom!=""&&nom!="TOTAL"&&nom!="Dossiers mis en réserve"&&nom!="TOTAL RESERVE"&&nom!="DOSSIERS REGLES")
		{
		//trouver l'adhérent avec nom prénom naissance
		let tab=[];
		
		console.log(json);
		tab[0]=json.NOM;
		tab[1]=json.PRENOM;		
		
		
		
		let params=guiUtils.arrayToString(tab);
		console.log("pp"+params);
		
		let str=config.sqlServerAddress()+'/gRaw/findAdh/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\","+params;
		console.log("str : "+str);
		request.get(str,(err,res,body)=>{
			console.log("parsed :"+body);
			 var parsed=JSON.parse(body);
			 let id=parsed[0].res;
			 if(id==-1)
			 {
				 alert("Adhérent "+tab[0]+" "+tab[1]+" non trouvé dans la base\nImpossible d'importer cette ligne");				 
			 }
			 else this.importDeposit(id,json);
			
		
		});
		}
	}
	
  parseCsv()
  {
	  let lineNumber=0;
	//  let _path='../xlsxAdh/adh.xlsx';
	  const csvFilePath=config.serverAddress()+'/csv/adh.csv';
console.log(csvFilePath);

	csv()
.fromStream(request.get(csvFilePath))
.subscribe((json)=>{
	this.importJsonLine(json);
	
    // process the json line in synchronous.
},this.onError, this.onComplete);



/*
      csv().fromStream(request.get(csvFilePath))
     .subscribe((json)=>{
      return new Promise((resolve,reject)=>{
		  console.log(json);
        // long operation for each json e.g. transform / write into database.
      })
      });*/
  }
   importFile()
   {
	   let self=this;
	let f=this.state.selectedFile;
//	self.parseFile();
	   
	const formData = new FormData(); 
      let ad=this.props.adhId;
	  console.log("f: "+f.name);
      // Update the formData object 
      formData.append( 
        "file", 
        f,
		'adh.csv'
      ); 
	   
     
      // Details of the uploaded file 
   //   console.log(formData); 
	    
      axios.post(config.serverAddress()+"/fzxdAdh", formData,{
		  onUploadProgress:(ProgressEvent)=>{
			  let _progress =Math.round(
			  ProgressEvent.loaded/ProgressEvent.total*100)+'%';
			  this.setState({progress:_progress});
			 // setProgress(progress);
		  }
	  }).then(function(response)
	  {
		  self.parseCsv();
	  });  
//	 
   }
   parseFile()
   {
	//   let f=this.state.selectedFile;
	   let _path='../xlsxAdh/adh.xlsx';
	   console.log("p :"+_path);
	   const workbook = new ExcelJS.Workbook();
	   workbook.xlsx.read(_path);
	   console.log(workbook);
	   const worksheet = workbook.worksheets[0];
	   const row = worksheet.getRow(0);
	 console.log(row);
   }
    render() { 
      return ( 
        <div> 
		    <div id="depositUploadForm"> <input class='text' type="file" name="sampleFile"  onChange={(e)=>this.setFile(e.target.files)} />
               <input class='text' type="button" value="Importer"  onClick={()=>this.importFile()} />
			   </div>
			<br /> 
			<div class='tip'>Le fichier doit être au format CSV, les champs du fichier importé doivent être séparés par des virgules et entourés chacun de guillemets doubles (touche 3 du clavier)</div>
        </div>); 
    } 
  } 
  
  export default DepositCsvUpload; 
