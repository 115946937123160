import React, { Component } from 'react';


var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');

//var md5=require("md5");
//var sha256=require("crypto-js/sha256");
//var bcrypt=require('bcryptjs');
//var sql=require("../sqlServer");


class AddCotisPayment extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',data:[],path:'',paymentTypes:'',amountEu:'0',_year:'',__year:'',paymentTypeId:'0'};
		this.getUserRights=this.getUserRights.bind(this);
		this.addPayment=this.addPayment.bind(this);
		this.setAmountFr=this.setAmountFr.bind(this);
		this.setAmountEu=this.setAmountEu.bind(this);
		this.setYear=this.setYear.bind(this);
		this.setTodayYear=this.setTodayYear.bind(this);
		this.setPaymentType=this.setPaymentType.bind(this);
		
	}
	
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({userRank:parsed[0].rank});
			this.setTodayYear();
			this.getPaymentTypes();
			
		});
	}
	setPaymentType(event)
	{
		console.log("pet "+event.target.value);
		this.setState({paymentTypeId:event.target.value});
	}
	componentDidMount()
	{
	    this.getUserRights();
	}
	
	setAmountEu(event)
	{
		this.setState({amountEu:event.target.value});
		
	}
	setAmountFr(event)
	{
		this.setState({amountFr:event.target.value});
		
	}
	setTodayYear()
	{
		var today= new Date();
		this.setState({_year:today.getFullYear()});
	}
	setYear(event)
	{

		this.setState({_year:event.target.value});
		
	}
	getPaymentTypes()
	{
		let str=config.sqlServerAddress()+'/gRaw/getPaymentTypes/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\""
		;
		console.log("str "+str);
		
		request.get(str,(err,res,body)=>{
			 var parsed=JSON.parse(body);
			let arr=guiUtils.getComboContent(parsed);
			this.setState({paymentTypes:arr});
			console.log("types :"+"<select id=\"pC\""+ arr+" />");
			
		});
	}
	
	addPayment()
	{
		let pT=this.state.paymentTypeId;
		let m=this.state.amountEu;
		if(pT==6) m=-m;
		let str=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		this.props.adhId+"\",\""+this.state._year+"-01-01\",\""+pT+"\",\""+m*6.55957+"\",\""+m+"\"";
		console.log("str "+str);
		
		request.get(str,(err,res,body)=>{
			
			if(pT==4)
			{
				let str2=config.sqlServerAddress()+'/gRaw/addAdhPayment/'
		        +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+
		        this.props.adhId+"\",\""+this.state._year+"-01-01\",\"5\",\""+m*6.55957+"\",\""+m+"\"";
		
				request.get(str2,(err2,res2,body2)=>{});
			}
			window.location=config.serverAddress()+"/adhCotisMan?adhId="+this.props.adhId;
		});
	}
	
	render()
	{
		let rank=this.state.userRank;
		let combo=this.state.paymentTypes;
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			
			return (<div id="AddPaymentForm">
			<div class='text'>Ajout Mouvement</div>
			<br/>
			
			<label for="amountEu">Montant EUR </label>
			<input class='text' type="text" id="amountEu" onChange={this.setAmountEu}/><br/>
			
			<label for="year">Année </label>
			<input class='text' type="text" id="year" onChange={this.setYear} value={this.state._year}/><br/>	
			<label for="c">Type de mouvement </label>
 <select id="c" dangerouslySetInnerHTML={{__html:combo}} onChange={this.setPaymentType} />
			<br/>
			<input class='text' type="button" value="Ajouter" onClick={()=>this.addPayment()} />
		 
			</div>);
			
		}
			else return (<div>Attendre</div>);	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AddCotisPayment;