var config=require('./config');


exports.sleep=function(ms)
{
	return new Promise(resolve => setTimeout(resolve,ms));
}
exports.makeHeader=function makeHeader(header)
{
	var str='<tr>';
	for(var i=0;i<header.length;i++)
	{
		str+="<th>"+header[i]+"</th>";
	}
	return str+"</tr>";
}
exports.jsonToString=function(data,header)
{
	let result='';
	let nC=header.length;
	let nL=data.length;
	for(var h=0;h<nC-1;h++)
	{
		result+="\""+header[h]+"\""+",";
	}
	result+="\""+header[nC-1]+"\""+"\n";
	
	
	for(var i=0;i<nL;i++)
	{
		const str=JSON.stringify(data[i]);
		let line=[];
		let l='';
		var z=0;
    	JSON.parse(str,(key,value)=>
	    {
		    line[z]="\""+value+"\"";
			z++;
	    });
		console.log("l"+line);
		for(var h=0;h<nC-1;h++)
	    {
			console.log(line)
		    l+=line[h]+",";
	    }
	    l+=line[nC-1]+"\n";
		console.log("ll"+l);
		result+=l;
	}	
	return result;	
}
exports.reshapeDateToSql=function(o)
{
	let arr=o.split("/");
	let day=arr[0];
	let month=arr[1];
	let year=arr[2];
	return year+"-"+month+"-"+day;
}
exports.getProductImage=function getProductImage(w,name,serverAddr)
{
	console.log(w+name+serverAddr);
	var w2=200;
	var w3=620;
	var f1="big";
	var f2="small";
	var f3=f1;
	if(w===w2)
	{
		w3=w2;
		f3=f2;
	}

	return "<img id='ProductImage'"
        +" onClick=showProductImage('ProductImage"+name+"',"+w3+",'"+name+"')"
		+" src='"+serverAddr+"/images/"+f3+"/"+name+"-"+w3+".jpg' alt='Aucune Photo'/>"
        ;	

}
exports.makeSmallImage=function makeSmallImage(fName)
{
	return "<div id='prodSmallImage'>"
		+"<img id='prodSmallImage"+fName+"'"
        +" onClick=showProductImage('prodSmallImage"+fName+"',620,'"+fName+"')"
		+" src='"+config.serverAddress()+"/images/small/"+fName+"-200.jpg' alt='Aucune Photo'/>"
        +"</div>";	
 }
exports.makeTd=function makeTd(tdType, tdData)
{
	if(tdData==null)
	{
		tdData="_";
	}
	if(tdData=="undefined")
	{
		tdData="_";
	}
	if(tdData=="")
	{
		tdData="_";
	}
	if(tdData.length<=0)
	{
		tdData="_";
	}
	else if(tdType=="basic")
	{
		return "<td>"+tdData+"</td>";
	}
	else if(tdType=="carDes")
	{
		return "<td><b>"+tdData+"</b></td>";
	}
	else if(tdType=="_sub")
	{
		return "<td>"+tdData+"</td>";
	}
	else if(tdType=="bold_basic")
	{
		return "<td><b>"+tdData+"</b></td>";
	}
	else if(tdType=="carId")
	{
		//	return "<td id="+tdData+">Voir Pieces</td>";
	
		return "<td id="+tdType+" onClick=showCarBom('_Wip','"+tdData+"');>Voir Les Pieces</td>";
	//	return "<td id="+tdData+"><a href=http://91.121.89.229:5000/showCarBom/"+tdData+">Voir Pieces</a></td>";
	}
	else if(tdType=="number")
	{
		return "<td>"+this.roundTo2(tdData)+"</td>";
		 
	}
	else if(tdType=="prixTTC")
	{
		return "<td><b>"+this.roundTo2(tdData)+" Euros</b></td>";
		 
	}
	else if(tdType=="ProductSpecsImage")
	{
		return "<td><image id='ProductSpecsImage' class ='ProductSpecsImage' src='"+config.serverAddress()+"/images/product_specs/"+tdData
		+"-200.jpg' alt=''/></td>";
	}
    else if(tdType=="prodSmallImage")
	{
		return this.makeSmallImage(tdData);	 
	}
	else if(tdType=="prodId")
	{
	  
	// console.log("<td id='prodTd'> <a id='prodTdA' href='http://91.121.89.229:5000/product/"+tdData+".html'><div>Fiche Produit</div></a></td>");
     return "<td id="+tdType+"> <a id='prodTdA' href='"+config.serverAddress()+"/products/"+tdData+"'><div>Fiche Produit</div></a></td>";   
	// return "<td id="+tdType+" onClick=showProduct('_Wip','"+tdData+"');"+">Fiche Produit</td>";    
	}
}
exports.startTr=function startTr()
{
	return "<tr id='apsTableTr'>";
}
exports.endTr=function endTr()
{
	return "</tr>";
}
exports.roundTo0=function roundTo0(val)
{
	var factor=Math.pow(10,0);
	return Math.round(val*factor)/factor;
}
exports.roundTo2=function roundTo2(val)
{
	var factor=Math.pow(10,2);
	return Math.round(val*factor)/factor;
}
exports.cleanString=function cleanString(_src,n,c)
{
	let te=_src;
//	return te;
if(c===1)
{
	for(var i=0;i<n;i++)
	{
		te=te.replace("'","");
		te=te.replace("/","");
		te=te.replace("-","");
		te=te.replace(" ","");
		te=te.replace("%","");
		te=te.replace("*","");
	}
}
else if(c===2)
{
		te=te.replace(/\D/g,"");
}
else if(c===3)
{
		te=te.replace(/\W/g,"");
}
else if(c===4)
{
	for(var i=0;i<n;i++)
	{
		te=te.replace("'","");
		te=te.replace("/","");
		te=te.replace("-","");
		te=te.replace("%","");
		te=te.replace("*","");
	}
}
else if(c===5)
{
	for(var i=0;i<n;i++)
	{
		te=te.replace("'"," ");
		te=te.replace("/"," ");
		te=te.replace("-"," ");
		te=te.replace("%"," ");
		te=te.replace("*"," ");
	}
}
else if(c===6)
{
	for(var i=0;i<n;i++)
	{
		te=te.replace("/","-");
		te=te.replace("%"," ");
		te=te.replace("*"," ");
	}
}
else if(c===7)
{
	for(var i=0;i<n;i++)
	{
		te=te.replace(" ","");
		te=te.replace("€","");
		te=te.replace(",",".");
	}
}
	return te;
}
exports.getSinglefield=function(fieldName,_array)
{
	let resRet="-1";
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
	
	if(fieldName.startsWith("numUsers"))
	{
		resRet=parsed[0].summed;
	}
	else if(fieldName.startsWith("isoCode"))
	{
		console.log("iso code :"+parsed.isoCode);
		resRet=parsed.isoCode;
	}
	else if(fieldName.startsWith("product_price"))
	{
	//	resRet=parsed;
	//	resRet=parsed[0].pu;
	    var factor=Math.pow(10,2);

		    resRet="<div id='product_price'>Prix HT : "+parsed.pu+"<br>Prix TTC : <b>"+Math.round(parsed.pu*1.2*factor)/factor+" Euros</b></div>";
		
	}
	return resRet;
}

exports.force2Digits=function(num)
{
	return num.toFixed(2);
}
exports.arrayToString=function(_array)
{
	let str="";
	let n=_array.length;
	console.log("s :"+n);
	if(n>0)
	{
		for(var i =0;i<n-1;i++)
	    {
			
		    str+="\""+this.cleanString(_array[i],256,6)+"\",";
	    }
		str+="\""+this.cleanString(_array[n-1],256,6)+"\"";		
	}
	return str;
}
exports.getCombo=function(name,__array)
{
	var json=JSON.stringify(__array);
	var parsed=JSON.parse(json);

//	console.log(parsed);
    console.log(__array.length);
	var html="<select id="+name+">";
	for(var v=0;v<__array.length;v++)
	{
		html+="<option value="+parsed[v].id+">"+__array[v].texte+"</option>";
	}
	return html+"</select>";
};
exports.getComboContent=function(__array)
{
	var json=JSON.stringify(__array);
	var parsed=JSON.parse(json);

//	console.log(parsed);
    console.log(__array.length);
	var html="";
	html+="<option value=0>Choisir </option>";
	for(var v=0;v<__array.length;v++)
	{
		html+="<option value="+parsed[v].id+">"+__array[v].texte+"</option>";
	}
	return html+"";
};
exports.getComboBox=function(procName,__array)
{
	var json=JSON.stringify(__array);
	var parsed=JSON.parse(json);

//	console.log(parsed);
    console.log(__array.length);
	var html="";
	for(var v=0;v<__array.length;v++)
	{
		html+="<option value="+parsed[v].id+">"+__array[v].texte+"</option>";
	}
	return html+"";
};
exports.getComboCountries=function(__array)
{
	var json=JSON.stringify(__array);
	var parsed=JSON.parse(json);

//	console.log(parsed);
    console.log(__array.length);
	var html="";
	for(var v=0;v<__array.length;v++)
	{
		html+="<option value='"+parsed[v].id+"'>"+__array[v].texte+"</option>";
	}
	return html+"";
};
exports.getBasketSum=function getBasketSum(_array)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
//	return parsed;
	return JSON.stringify(this.roundTo2(parseFloat(parsed[0].total_ttc)));	
}
function canFranco(sum,franco)
{

	if(sum-franco>=0)
	{
		return 0;
	}
	return 12;
}
exports.getShipmentCosts=function getShipmentCosts(basketSum,_address)
{

	if(_address=="0")
	{
		return JSON.stringify(21);
	}
	var json2=JSON.stringify(_address[0]);
	var parsed2=JSON.parse(json2);
if(parsed2.length>0)
{
	var country=parsed2[0].pays;
	
	var postCode=parsed2[0].code_postal;
	
	var shipmentCosts=canFranco(this.roundTo2(basketSum),this.roundTo2(120));
	
	

	if(country=="FR")
	{
	    if(postCode.startsWith("20"))
	    {
		    shipmentCosts=21;
	    }
	}
	if(country!="FR")
	{
		shipmentCosts=21;
	}
	
	return JSON.stringify(shipmentCosts);

}
else return 12;	
}


exports.deliveryAddressesToTable=function deliveryAddressesToTable(_array)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
	if(parsed.length>0)
	{
//	return parsed;
	var header=[];
	var htmlRes="<div>Adresse de livraison :</div>";
	
	htmlRes+="Nom : "+parsed[0].nom+"<br>";
	htmlRes+="Adresse 1 : "+parsed[0].adresse1+"<br>";
	htmlRes+="Adresse 2 : "+parsed[0].adresse2+"<br>";
	htmlRes+="Code Postal : "+parsed[0].code_postal+"<br>";
	htmlRes+="Ville : "+parsed[0].ville+"<br>";
	htmlRes+="Pays : "+parsed[0].pays+"<br>";
	htmlRes+="Téléphone : "+parsed[0].tel;
	
	return htmlRes;
	
	}
	else return "undefined";
}
exports.test=function()
{
	return "tested";
}
exports.customerInvoices=function (_array,_u)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
//	return parsed;
	var header=[];
	var htmlRes="<div></div><table id='apsTable'>";
	header.push('Num Facture','Ref',"Quantité","PU HT","PU TTC","-");
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+=this.makeTd("basic",parsed[i].num_fac);
			htmlRes+=this.makeTd("basic",parsed[i].ref_cde);
			htmlRes+=this.makeTd("basic",parsed[i].qte_liv);
			htmlRes+=this.makeTd("basic",this.roundTo2(parsed[i].prix_u));
			htmlRes+=this.makeTd("basic",this.roundTo2(parsed[i].prix_u*1.2));
			htmlRes+="<td><input id='askInvoice' type='button' value='Renvoyer facture' onClick=askInvoiceResend("+parsed[i].num_fac+","+_u+")";
			htmlRes+="<td><input id='askInvoice' type='button' value='Retour Pièce' onClick=askInvoiceLineReturn()";
			htmlRes+=this.endTr();
		}
		
		
		htmlRes+="</table>";
	
	return htmlRes;
}
exports.getEmptySpace=function getEmptySpace(numLines)

	{
		var r="";
		for(var i=0;i<numLines;i++)
		{
			r+="<br />";
		}
		return r;
			
	}
exports.basketToTable=function basketToTable(userId,_array)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
	var numLines=parsed.length;
//	return parsed;
//if(parsed.length>0)
var htmlRes="";
if(numLines>0)
{
	var header=[];
	var htmlRes="<div>Contenu Panier :</div><br><table id='apsTable'>";
	header.push('Ref','Photos','Quantité',"PU HT","PU TTC","Total Ligne TTC","-","+","X");
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			
			htmlRes+=this.makeTd("_sub","<input id='showProductPageB' type='button' value='"+parsed[i].refa+"' onClick=showProductPage("+parsed[i].idProd+") />");
			htmlRes+=this.makeTd("_sub","<div id='Photo_Produit'><img src="+config.serverAddress()+"/images/small/"+parsed[i].refa+"-200.jpg onClick=showProductPage("+parsed[i].idProd+") />");
			htmlRes+=this.makeTd("basic",parsed[i].qte);
			
			htmlRes+=this.makeTd("basic",parsed[i].puht);
			htmlRes+=this.makeTd("basic",this.roundTo2(parsed[i].puht*1.2));
			htmlRes+=this.makeTd("bold_basic",this.roundTo2(parsed[i].ptttc)+" Euros");
			htmlRes+="<td><input id='_incBasketLineButton' type='button' value='-' onClick=incBasketLine('_Wip','"+userId+"',"+parsed[i].basketId+","+(parsed[i].qte-1)+") /></td>";
			htmlRes+="<td><input id='_incBasketLineButton' type='button' value='+' onClick=incBasketLine('_Wip','"+userId+"',"+parsed[i].basketId+","+(parsed[i].qte+1)+") /></td>";
			htmlRes+="<td><input id='_deleteBasketLineButton' type='button' value='X' onClick=deleteBasketLine('_Wip','"+userId+"',"+parsed[i].basketId+") /></td>";
			htmlRes+=this.endTr();
		}
		
		
		htmlRes+="</table>";
}
else htmlRes="Votre panier est vide";
	return htmlRes;
}
exports.arrayToArray=function arrayToArray(_array)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
	console.log("ll "+parsed.length);
	return parsed;
}
exports.getProductSpecsTable=function getProductSpecsTable(_array)
{
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
//	return parsed;
	var header=[];
	var htmlRes="<table id='table' class='Specs='ProductSpecsTable1'>";
		header.push('Type','Valeur','_');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+="<tr class='noBorder'>";
		//	htmlRes+=this.makeTd("basic",parsed[i].id);
			htmlRes+=this.makeTd("basic",parsed[i].famille);
			htmlRes+=this.makeTd("basic",parsed[i].valeur);
			htmlRes+=this.makeTd("ProductSpecsImage",parsed[i].valeur);
			htmlRes+=this.endTr();
		}
		htmlRes+="</table>";
		return htmlRes;
}
exports.arrayToTable=function arrayToTable(_array,procName)
{
	//return _array[0][0];
	var json=JSON.stringify(_array[0]);
	var parsed=JSON.parse(json);
//	return parsed;
	var header=[];
	var htmlRes="<table id='apsTable' class='noBorder'>";
	if(procName.startsWith('call vehicules.getBrands'))
	{
		header.push('id','marque');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+=this.makeTd("basic",parsed[i].id);
			htmlRes+=this.makeTd("basic",parsed[i].texte);
			htmlRes+=this.endTr();
		}
		
	}
	
	else if(procName.startsWith('call vehicules.getBrandCarsDes'))
	{
		header.push('id','des');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+=this.makeTd("basic",parsed[i].id);
			htmlRes+=this.makeTd("basic",parsed[i].des);
			htmlRes+=this.endTr();
		}
	}
	else if(procName.startsWith('call vehicules.getBrandDesCars'))
	{
		header.push('Des-typ','de-a','kw-cv-cc','Selection');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
		//	htmlRes+=this.makeTd("basic",parsed[i].marque);
			htmlRes+=this.makeTd("carDes",parsed[i].Des+"<br>"+parsed[i].typ);
			
			htmlRes+=this.makeTd("basic",parsed[i].de+"<br>"+parsed[i].a);
			
			htmlRes+=this.makeTd("basic",parsed[i].kw+" "+parsed[i].cv+" "+parsed[i].cc);

			htmlRes+=this.makeTd("_sub","<input id='showCarBomB' type='button' value='Voir les pièces' onClick=showCarBom("+parsed[i].id+") />");
			htmlRes+=this.endTr();
		}
	}
	else if(procName.startsWith('call vehicules.getBom'))
	{
		header.push('Photo','Famille','Ref','Dispo','PUHT','PU TTC','Voir Fiche');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+="<td>"+this.makeTd("prodSmallImage",parsed[i].ref1)+"</td>";
			htmlRes+=this.makeTd("basic",parsed[i].fam);
			htmlRes+=this.makeTd("basic",parsed[i].ref2);
			
			htmlRes+=this.makeTd("basic",parsed[i].dispo);
			htmlRes+=this.makeTd("number",parsed[i].puht);
			htmlRes+=this.makeTd("prixTTC",parsed[i].puht*1.2);
			htmlRes+=this.makeTd("_sub","<input id='showProductPageB' type='button' value='Voir Plus' onClick=showProductPage("+parsed[i].id+") />");

		//	htmlRes+=this.makeTd("basic",parsed[i].puht*1.2);
			htmlRes+=this.endTr();
		}
	}
	
	else if(procName.startsWith('call aps_web.getProductOems'))
	{
		header.push('Oem','Fabricant');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+=this.makeTd("basic",parsed[i].oem);
			htmlRes+=this.makeTd("basic",parsed[i].fabricant);
			htmlRes+=this.endTr();
		}
	}
	else if(procName.startsWith('call vehicules.getTurboCars'))
	{
		header.push('idvehic','marque','type','moteur','de','a','kw','cv','cc');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			htmlRes+=this.makeTd("basic",parsed[i].idvehic);
			htmlRes+=this.makeTd("basic",parsed[i].marque);
			htmlRes+=this.makeTd("basic",parsed[i].Des);
			htmlRes+=this.makeTd("basic",parsed[i].typ);
			htmlRes+=this.makeTd("basic",parsed[i].de);
			htmlRes+=this.makeTd("basic",parsed[i].a);
			htmlRes+=this.makeTd("basic",parsed[i].kw);
			htmlRes+=this.makeTd("basic",parsed[i].cv);
			htmlRes+=this.makeTd("basic",parsed[i].cc);
			htmlRes+=this.endTr();
		}
	}
	else if(procName.startsWith('call aps_web.findX'))
	{
		header.push('Famille','MARQUE','OEM','APS','Delai','SELECTION');
		htmlRes+=this.makeHeader(header);
		for(var i=0;i<parsed.length;i++)
		{
			htmlRes+=this.startTr();
			
			htmlRes+=this.makeTd("basic",parsed[i].fam);
			htmlRes+=this.makeTd("basic",parsed[i].manufT);
			htmlRes+=this.makeTd("basic",parsed[i].src);
			htmlRes+="<td>"+this.makeTd("prodSmallImage",parsed[i].ref)+"</td>";
			htmlRes+=this.makeTd("basic",parsed[i].delai);
			htmlRes+=this.makeTd("_sub","<input id='showProductPageB' type='button' value='Voir Plus' onClick=showProductPage("+parsed[i].idProd+") />");
			htmlRes+=this.endTr();
		}
	}
	htmlRes+="</table>";
	
	return htmlRes;
};