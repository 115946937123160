import React, { Component } from 'react';

import './App.css';
import MainHeader from './mainHeader';
import MainMenu from './mainMenu';
import Routeur from './routeur';



export class App extends Component {
	constructor(props){
		super(props);
		this.state={mainHeader:'', mainMenu:''};
	}
// <HeaderMenu />
	// coreUtils.verifyUserIp();
  render() {
	 
	  return (
	<div id='_main'>
	  <div id="h">
	   <MainHeader />  
 
<Routeur />
	  </div>
	  <div id="_m">
	  
	  
	  </div>
	  
	 </div>
    );
  }
}

export default App;
