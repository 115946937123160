import React, { Component } from 'react';
import Menu, {SubMenu,MenuItem} from 'rc-menu';
import 'rc-menu/assets/index.css';
//import animate from 'css-animation';
//import ReactDOM from 'react-dom';
//import './mainMenu.css';

var config=require('./config');
var rrr="0";
//var request=require('request');
class MainMenu extends Component{
	constructor(props)
	{
		super(props);
		this.state={userRank:'0',adminMenu:''};
		
		this.handleSelect=this.handleSelect.bind(this);
		this.handleClick=this.handleClick.bind(this);
		this.getAdminMenu=this.getAdminMenu.bind(this);
		this.getUserRights=this.getUserRights.bind(this);
	this.setRank=this.setRank.bind(this);
	
	}
	getUserRights()
	{
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		
		fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				self.setState({userRank:data[0].rank});
				
			}).catch(err=>{console.log('caught',err);
			});		
			
	}
componentDidMount()
{
	this.getUserRights();
	//this.getAdminMenu();
		 
}
    showSettings(event)
	{
		event.preventDefault();
	}

	handleSelect(info)
	{
	//	console.log("click");
	}
	handleClick(info)
	{
		if(info.key==='Accueil')
		{
		    window.location=config.serverAddress();
		}
		
		else if(info.key==='getUserLoginForm')
		{
		    window.location=config.serverAddress()+"/login";
		}
		else if(info.key==='getUserAddForm')
		{
		    window.location=config.serverAddress()+"/userAdd";
		}
	
		else if(info.key==='_getAdminMenu')
		{
			window.location=config.serverAddress()+"/admin";
			console.log("dd");
		}
		else if(info.key==='_getAdhMenu')
		{
			window.location=config.serverAddress()+"/adh";
			console.log("dd");
		}
		else if(info.key==='_getOldAdhMenu')
		{
			window.location=config.serverAddress()+"/oldAdh";
			console.log("dd");
		}
		else if(info.key==='_getDepositTable')
		{
			window.location=config.serverAddress()+"/adhDeposits";
			console.log("dd");
		}
		else if(info.key==='_importDepositTable')
		{
			window.location=config.serverAddress()+"/importDepositTable";
			console.log("dd");
		}
		
		else if(info.key==='_getCotisTable')
		{
			window.location=config.serverAddress()+"/cotisTable";
			console.log("dd");
		}
		
	}
	getAdminMenu()
	{
		
		//let self=this;
		if(sessionStorage.getItem("u")!="")
		{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\"";
		
		fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				
				return response.json();
			}).then(function(data){
				let re=data[0];
				
				rrr=re.rank;
				console.log(rrr);
			///	document.getElementById("zzz").innerHTML= "";
				
			}).catch(err=>{console.log('caught',err);
			});	
		}
		
	}
	setRank(ni)
	{
		this.setState({userRank:ni});
	}
	getMenu()
	{
		
	}
	render()
	{
		let rrr=this.state.userRank;
		let mmm="";
		if(rrr==2)
		{
			
		
			return (
		<div id='_mainMenu'>
		<Menu id='__mainMenu' 
		onSelect={this.handleSelect}
		onClick={this.handleClick}
		mode='horizontal'
		openAnimation='slide-up'
		triggerSubMenuAction='click'
		>
		  <MenuItem class='menuItem' key="Accueil">Accueil</MenuItem>
			  <SubMenu key="_admin" title="Gestion Compte">
		          <MenuItem class='menuItem' key="getUserLoginForm" >Connection</MenuItem>
		          <MenuItem class='menuItem' key="getUserAddForm" >Cr&#233;er Compte</MenuItem>			
				
		      </SubMenu>
			  
			  <MenuItem class='menuItem' key='_getAdminMenu'>Administrateurs</MenuItem>
			  
			  <SubMenu key="_adhAdmin" title="Adhérents">
      	        <MenuItem id='zzz2' key="_getAdhMenu">Actifs</MenuItem>
			 	<MenuItem id='zzz3' key="_getOldAdhMenu">Archivés</MenuItem>	
			  </SubMenu>
			   
			  			   
	     
		   <MenuItem class='menuItem' key='_getCotisTable'>Cotisations</MenuItem>
		        <MenuItem class='menuItem' key='_getDepositTable'>Dépôts</MenuItem>
		
			  
		</Menu></div>
		
		);
		}
		else {
			
		
			return (
		<div>
		<Menu 
		onSelect={this.handleSelect}
		onClick={this.handleClick}
		mode='horizontal'
		openAnimation='slide-up'
		triggerSubMenuAction='click'
		>
		  <MenuItem key="Accueil">Accueil</MenuItem>
			  <SubMenu key="_admin" title="Gestion Compte">
		          <MenuItem class='menuItem' key="getUserLoginForm" >Connection</MenuItem>
		          <MenuItem class='menuItem' key="getUserAddForm" >Cr&#233;er Compte</MenuItem>			
				
		      </SubMenu>
		</Menu></div>
		
		);
		}
	}

}

export default MainMenu;
