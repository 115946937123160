import React, { Component } from 'react';
import FileUpload from '../fileUpload';
import AddPayment from './addPayment';
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');


class AdhPaymentsMan extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',adhId:'0',refundSum:'',refunds:[],lastActu:[],actus:[],deposit:[],path:'',adhDetails:[],actuSum:0,reserveSum:''};
		this.getUserRights=this.getUserRights.bind(this);
	    this.getAdhId=this.getAdhId.bind(this);
		this.getAddPaymentForm=this.getAddPaymentForm.bind(this);
		this.getAdhActus=this.getAdhActus.bind(this);
		this.getAdhRefunds=this.getAdhRefunds.bind(this);
		this.getAdhLastActu=this.getAdhLastActu.bind(this);
		this.getAdhRefundsSum=this.getAdhRefundsSum.bind(this);
		this.getAdhDeposit=this.getAdhDeposit.bind(this);
		this.getDetails=this.getDetails.bind(this);
		this.renderDeposit=this.renderDeposit.bind(this);
		this.renderLastActu=this.renderLastActu.bind(this);
		this.renderRemain=this.renderRemain.bind(this);
		this.renderRefund=this.renderRefund.bind(this);
		this.getAdhActuSum=this.getAdhActuSum.bind(this);
		this.renderActus=this.renderActus.bind(this);
		this.renderActusSum=this.renderActusSum.bind(this);
		this.renderReserveSum=this.renderReserveSum.bind(this);
		this.getAdhReserveSum=this.getAdhReserveSum.bind(this);
	}
	getAddPaymentForm()
	{
		return (<AddPayment adhId={this.state.adhId}/>);
	}
	
	getAdhRefundsSum()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhRefundsSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			 console.log("ref:"+parsed[0].sumEU);
			this.setState({refundSum:guiUtils.roundTo2(parsed[0].sumEU)});
			
		});
	}
	getAdhReserveSum()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhReserveSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			 console.log("ref:"+parsed[0].sumEU);
			this.setState({reserveSum:guiUtils.roundTo2(parsed[0].sumEU)});
			
		});
	}
	getAdhRefunds()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhPaymentsSubTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\"6\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({refunds:parsed});
			
		});
	}
	getAdhActuSum()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhActusSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({actuSum:guiUtils.roundTo2(parsed[0].sumEU)});			
		});
	}
	getAdhLastActu()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhLastPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\"5\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({lastActu:guiUtils.roundTo2(parsed[0])});			
		});
	}
	
	getAdhActus()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhPaymentsSubTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\"5\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({actus:parsed});
			
		});
	}
	getAdhDeposit()
	{
			let str=config.sqlServerAddress()+'/gRaw/getAdhLastPayment/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\"4\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({deposit:parsed[0]});
		//	console.log(parsed);			
		});
	}
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			var parsed=JSON.parse(body);
			this.setState({userRank:parsed[0].rank});
			this.getAdhId();
		});
	}
	getAdhId()
	{
		this.setState({adhId:window.location.search.replace("?adhId=","")});
		this.getDetails();
		this.getAdhRefundsSum();
		this.getAdhRefunds();
		this.getAdhActus();
		this.getAdhDeposit();
		this.getAdhLastActu();
		this.getAdhActuSum();
//	this.renderReserveSum();
		
	}
	componentDidMount()
	{
	    this.getUserRights();
	}
	getDetails()
	{
		let ad=this.state.adhId;
		if(ad!=0)
		{
			 let str=config.sqlServerAddress()+'/gRaw/getAdhDetails/'
		    +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+ad+"\"";
    //    console.log(str);
		    request.get(str,(err,res,b)=>
			{
				 var parsed=JSON.parse(b);
			     this.setState({adhDetails:parsed[0]});
			//	 console.log(parsed);
	               }); 
		}
		
    } 
	renderReserveSum()
	{
	//	if(this.state.lastActu)
	//	{
			return(<div class='text'>Somme Réserves :
		
		    {this.state.reserveSum} Euros</div>);
	//	}
	//	else return (<div class='text'>Somme Actualisations : Aucune </div>);
	}
	renderLastActu()
	{
		if(this.state.lastActu)
		{
			return(<div class='text'>Dernière Actualisation :
		
		    {this.state.lastActu.amountEU} Euros en {this.state.lastActu._date}</div>);
		}
		else return (<div class='text'>Dernière Actualisation : Aucune </div>);
	}
	renderActusSum()
	{
	//	if(this.state.lastActu)
	//	{
			return(<div class='text'>Somme Actualisations :
		
		    {this.state.actuSum} Euros</div>);
	//	}
	//	else return (<div class='text'>Somme Actualisations : Aucune </div>);
	}
	renderDeposit()
	{
	    if(this.state.deposit)
        {
			return ( <div class='text'>Dépôt :  {guiUtils.roundTo2(this.state.deposit.amountEU)} Euros</div>);
		}	
		else return (<div class='text'>Dépôt : Aucun</div>);
	}
	renderRemain()
	{
		if(this.state.deposit)
        {
		    let r=guiUtils.roundTo2(this.state.deposit.amountEU+this.state.actuSum+this.state.refundSum);
		    return (<div class='text'>Réserve :{r} Euros</div>);
		}
		else return (<div class='text'>Réserve : 0 Euros</div>);
	//	console.log("act:");
	//	console.log(this.state.lastActu);
	/*
		if(this.state.lastActu>=0)
		{
			if(this.state.refundSum>=0)
			{
				return (<div class='text'>Reste :{this.state.deposit.amountEU+this.state.actuSum.amountEU+this.state.refundSum} Euros</div>);
			}
			else return (<div class='text'>Reste :{this.state.deposit.amountEU+this.state.actuSum.amountEU}</div>);
		}
		else return (<div class='text'>Reste :{this.state.deposit.amountEU}</div>);*/
	}
	renderRefund()
	{
		if(this.state.refundSum>=0) {
			return(<div class='text'>Somme remboursée : {this.state.refundSum}</div>);
		}
		else return (<div class='text'>Somme remboursée : Aucune</div>);
	}
	
	renderActus()
	{
		return (<div><div class='text'>Historique Actualisations :</div>		
		<table> 
		<thead>
		<tr>
		<th>Année</th>
		<th>Montant FRF</th>
		<th>Montant EUR</th>
		<th>Type</th>
		</tr>
		</thead>
		<tbody>
		{this.state.actus.map(member =>
		
		<tr key={member.id}>
		<td>{member._date} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member.texte} </td>		
		</tr>)}
		</tbody>
		</table></div>);
	}
	renderRefunds()
	{
		return (<div><div class='text'>Historique Remboursements :</div>
		
		<table> 
		<thead>
		<tr>
		<th>Année</th>
		<th>Montant FRF</th>
		<th>Montant EUR</th>
		<th>Type</th>
		</tr>
		</thead>
		<tbody>
		{this.state.refunds.map(member =>
		
		<tr key={member.id}>
		<td>{member._date} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member.texte} </td>		
		</tr>)}
		</tbody>
		</table></div>
		);
	}
	render()
	
	{
		
	//	let reste=this.renderRemain();
		let det=this.state.adhDetails;
		return (<div id="AdhPaymentsTable">
	    <div class='text'>Adhérent : {det.nom} {det.prenom}</div>
		<br/>
		{this.getAddPaymentForm()}
		<br />
		<br />
		<div id ="paymentsSynthesis">
		<div class='text'>Synthèse</div>
		<br/>
		{this.renderDeposit()}
		{this.renderActusSum()}
		{this.renderRefund()}
		 {this.renderRemain()}
		</div>
		<br />
		{this.renderActus()}
		<br />
		<br />
		{this.renderRefunds()}
		</div>);
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhPaymentsMan;