import React, { Component } from 'react';
import FileUpload from '../fileUpload';
import AddCotisPayment from './addCotisPayment';
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');


class AdhCotisMan extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',adhId:'0',path:'',cotis:[],adhDetails:[]};
		this.getUserRights=this.getUserRights.bind(this);
	    this.getAdhId=this.getAdhId.bind(this);
		this.getAddPaymentForm=this.getAddPaymentForm.bind(this);

		this.getDetails=this.getDetails.bind(this);

		this.getAdhCotis=this.getAdhCotis.bind(this);

	}
	getAddPaymentForm()
	{
		return (<AddCotisPayment adhId={this.state.adhId}/>);
	}
	
	
	
	
	getAdhCotis()
	{
		let str=config.sqlServerAddress()+'/gRaw/getAdhPaymentsSubTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\",\"7\"";
		
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({cotis:parsed});			
		});
	}
	
	
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			var parsed=JSON.parse(body);
			this.setState({userRank:parsed[0].rank});
			this.getAdhId();
		});
	}
	getAdhId()
	{
		this.setState({adhId:window.location.search.replace("?adhId=","")});
		this.getDetails();
		
		this.getAdhCotis();
		
	}
	componentDidMount()
	{
	    this.getUserRights();
	}
	getDetails()
	{
		let ad=this.state.adhId;
		if(ad!=0)
		{
			 let str=config.sqlServerAddress()+'/gRaw/getAdhDetails/'
		    +"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+ad+"\"";
        console.log(str);
		    request.get(str,(err,res,b)=>
			{
				 var parsed=JSON.parse(b);
			     this.setState({adhDetails:parsed[0]});
				 console.log(parsed);
	               }); 
		}
		
    } 
	
	
	renderCotis()
	{
		return (<div><div class='text'>Historique Cotisations :</div>
		
		<table> 
		<thead>
		<tr>
		<th>Année</th>
		<th>Montant FRF</th>
		<th>Montant EUR</th>
		<th>Type</th>
		</tr>
		</thead>
		<tbody>
		{this.state.cotis.map(member =>
		
		<tr key={member.id}>
		<td>{member._date} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member.texte} </td>		
		</tr>)}
		</tbody>
		</table></div>);
	}
	
	
	render()
	
	{
		
	//	let reste=this.renderRemain();
		let det=this.state.adhDetails;
		return (<div id="AdhCotisTable">
	    <div class='text'>Adhérent : {det.nom} {det.prenom}</div>
		<br/>
		{this.getAddPaymentForm()}
		<br />
		<br />
		
		{this.renderCotis()}
		
		</div>);
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhCotisMan;