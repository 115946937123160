import React, { Component } from 'react';
 
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');


class AdhDeposits extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',data:[],refundSum:'',actuSum:'',depositSum:'',reserveSum:''};
		this.getUserRights=this.getUserRights.bind(this);
		this.getTable=this.getTable.bind(this);
		this.viewAdhPayments=this.viewAdhPayments.bind(this);
		this.exportCsv=this.exportCsv.bind(this);
		this.importCsv=this.importCsv.bind(this);
		
		this.getActusSum=this.getActusSum.bind(this);
		this.getRefundsSum=this.getRefundsSum.bind(this);
		this.getDepositSum=this.getDepositSum.bind(this);
		this.getReserveSum=this.getReserveSum.bind(this);
	}
	viewAdhPayments(adhId)
	{
		window.location=config.serverAddress()+"/adhPaymentsMan?adhId="+adhId;
	}
	getReserveSum()
	{
		let str=config.sqlServerAddress()+'/gRaw/getReserveSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({reserveSum:parsed[0].sumEU});
			
		});
	}
	getDepositSum()
	{
		let str=config.sqlServerAddress()+'/gRaw/getTypeSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\"4\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({depositSum:parsed[0].sumEU});
			
		});
	}
	getRefundsSum()
	{
		let str=config.sqlServerAddress()+'/gRaw/getTypeSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\"6\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({refundSum:parsed[0].sumEU});
			
		});
	}
	getActusSum()
	{
		let str=config.sqlServerAddress()+'/gRaw/getTypeSum/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\"5\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({actuSum:parsed[0].sumEU});
			
		});
	}
	getTable()
	{
		let str=config.sqlServerAddress()+'/gRaw/getAccountTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({data:parsed});
			
		});
	}
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({userRank:parsed[0].rank});
			this.getDepositSum();
			this.getActusSum();
			this.getRefundsSum();
		//	this.getReserveSum();
			
		});
			
	}
	
	componentDidMount()
	{
	    this.getUserRights();
	}
	exportCsv()
	{
		let dataEx=[];
		let str=config.sqlServerAddress()+'/gRaw/getAccountTable/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				dataEx=data;
				let header=["id","Nom","Prénom","Naissance","FRF","EUR","Année","Type"];
				let str=guiUtils.jsonToString(data,header);
				console.log(str);
				var a=document.createElement("a");
				var file=new Blob([str],{type:'text/plain'});
				a.href=URL.createObjectURL(file);
				a.download="depots.csv";
				a.click();
			//	self.forceUpdate();
			}).catch(err=>{console.log('caught',err);
			});
	}
	importCsv()
	{
		window.location=config.serverAddress()+"/importDepositTable";
	}
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			return (<div id="adhDeposits">
			Dépôts : {guiUtils.roundTo2(this.state.depositSum)} Euros
			<br/>
			Actualisations : {guiUtils.roundTo2(this.state.actuSum)} Euros
			<br/>
			Remboursements : {guiUtils.roundTo2(this.state.refundSum)} Euros
			<br/>
			Réserve : {guiUtils.roundTo2(this.state.depositSum)+ guiUtils.roundTo2(this.state.actuSum)+ guiUtils.roundTo2(this.state.refundSum)} Euros
			<br/>
			<input type='button' value="Importer Fichier CSV" onClick={()=>this.importCsv()} />
		<input type='button' value="Exporter Fichier CSV" onClick={()=>this.exportCsv()} />
			</div>)
			/*
			return (<div id="adhDeposits"><br/><div class='text'>Relevé du Compte Caisse de Dépôt</div><br/>
		<table> 
		<thead>
		<tr>
		<th>Nom</th>
		<th>Prénom</th>
		<th>Naissance</th>
		<th>FRF</th>
		<th>EUR</th>
		<th>Année</th>
		<th>Type</th>
		</tr>
		</thead>
		<tbody>
		{this.state.data.map(member =>
		
		<tr key={member.rlid}>
		<td>{member.nom} </td>
		<td>{member.prenom} </td>
		<td>{member.naissance} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member._date} </td>
		<td>{member.texte} </td>
		<td><input type='button' value='Filtrer' onClick={()=>this.viewAdhPayments(member.id)} /></td>
		</tr>
		
		)}
		</tbody>
		</table>
		
		</div>);*/
			
		}
			else return <div>Attendre</div>;	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhDeposits;