import React, { Component } from 'react';
 
var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');


class CotisMan extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',okdata:[],nokdata:[]};
		this.getUserRights=this.getUserRights.bind(this);
		this.viewAdhCotis=this.viewAdhCotis.bind(this);
		
		this.getOkTable=this.getOkTable.bind(this);
		this.getNokTable=this.getNokTable.bind(this);
		this.exportCsv=this.exportCsv.bind(this);
		
		this.renderOk=this.renderOk.bind(this);
		this.renderNok=this.renderNok.bind(this);
	}
	
	viewAdhCotis(adhId)
	{
		window.location=config.serverAddress()+"/adhCotisMan?adhId="+adhId;
	}
	getOkTable()
	{
		let str=config.sqlServerAddress()+'/gRaw/getOkCotisTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({okdata:parsed});
			
		});
	}
	getNokTable()
	{
		let str=config.sqlServerAddress()+'/gRaw/getNokCotisTable/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\"";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({nokdata:parsed});
			
		});
	}
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({userRank:parsed[0].rank});
			this.getOkTable();
			this.getNokTable();
		});
			
	}
	
	componentDidMount()
	{
	    this.getUserRights();
	}
	exportCsv(fileName)
	{
		let dataEx=[];
		let str=config.sqlServerAddress()+'/gRaw/getAccountTable/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				dataEx=data;
				let header=["id","Nom","Prénom","Naissance","FRF","EUR","Année","Type"];
				let str=guiUtils.jsonToString(data,header);
				console.log(str);
				var a=document.createElement("a");
				var file=new Blob([str],{type:'text/plain'});
				a.href=URL.createObjectURL(file);
				a.download=fileName+".csv";
				a.click();
			//	self.forceUpdate();
			}).catch(err=>{console.log('caught',err);
			});
	}
	
	renderOk()
	{
		return (<div id="cotisOk"><br/><div class='text'>Cotisations à jour</div><br/>
		<table> 
		<thead>
		<tr>
		<th>Nom</th>
		<th>Prénom</th>
		<th>Naissance</th>
		<th>FRF</th>
		<th>EUR</th>
		<th>Année</th>
		</tr>
		</thead>
		<tbody>
		{this.state.okdata.map(member =>
		
		<tr key={member.rlid}>
		<td>{member.nom} </td>
		<td>{member.prenom} </td>
		<td>{member.naissance} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member._date} </td>
		
		<td><input type='button' value='Filtrer' onClick={()=>this.viewAdhCotis(member.id)} /></td>
		</tr>
		
		)}
		</tbody>
		</table>
		<input type='button' value="Exporter Fichier CSV" onClick={()=>this.exportCsv("cotisations_a_jour")} />
		</div>);
	}
	renderNok()
	{
		return (<div id="cotisNok"><br/><div class='text'>Cotisations en retard</div><br/>
		<table> 
		<thead>
		<tr>
		<th>Nom</th>
		<th>Prénom</th>
		<th>Naissance</th>
		<th>FRF</th>
		<th>EUR</th>
		<th>Année</th>
		</tr>
		</thead>
		<tbody>
		{this.state.nokdata.map(member =>
		
		<tr key={member.rlid}>
		<td>{member.nom} </td>
		<td>{member.prenom} </td>
		<td>{member.naissance} </td>
		<td>{member.amountFR} </td>
		<td>{member.amountEU} </td>
		<td>{member._date} </td>
		
		<td><input type='button' value='Filtrer' onClick={()=>this.viewAdhCotis(member.id)} /></td>
		</tr>
		
		)}
		</tbody>
		</table>
		<input type='button' value="Exporter Fichier CSV" onClick={()=>this.exportCsv("cotisions_manquantes")} />
		</div>);
	}
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			return(<div id="cotis">
			{this.renderOk()}
			<br/>
		{this.renderNok()}</div>
		);
			
		}
			else return <div>Attendre</div>;	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default CotisMan;