import React, { Component } from 'react';


var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');

//var md5=require("md5");
//var sha256=require("crypto-js/sha256");
//var bcrypt=require('bcryptjs');
//var sql=require("../sqlServer");


class AdhUpdate extends Component {
	constructor(props){
		super(props);
		this.state={userRank:'',adhId:'0',data:[],path:''};
		this.getUserRights=this.getUserRights.bind(this);
		this.getAdh=this.getAdh.bind(this);
		this.updateAdh=this.updateAdh.bind(this);
		this.updateField=this.updateField.bind(this);
	}
	
	getAdh()
	{
		console.log("p :"+window.location.search);
		this.setState({adhId:window.location.search.replace("?adhId=","")});
		var sa=this.state.adhId;
		
		let str=config.sqlServerAddress()+'/gRaw/getAdh/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\",\""+this.state.adhId+"\"";
		request.get(str,(err,res,body)=>{
			console.log("str : "+str);
			 var parsed=JSON.parse(body);
			console.log(parsed[0]);
      		this.setState({data:parsed[0]});
			console.log("idd : "+this.state.data.id);
		//	this.updateForm();
		});
	}
   updateAdh()
	{
		let d=this.state.data;
		let r=[];
		r[0]=this.state.adhId;
		console.log("idif :"+r[0]);
		r[1]=d.nom;
		r[2]=d.nom_jeune_fille;
		r[3]=d.prenom;
		r[4]=d.naissance;
		r[5]=d.adresse;
		r[6]=d.adresse2;
		r[7]=d.code_postal;
		r[8]=d.ville;
		r[9]=d.tel;
		r[10]=d.T;
		r[11]=d.D;
		r[12]=d.M;
		r[13]=d.SC;
		r[14]=d.resp;
		r[15]=d.dest;
		let params=guiUtils.arrayToString(r);
		console.log(params);
		
		let str=config.sqlServerAddress()+'/gRaw/updateAdh/'
		+"\""+sessionStorage.getItem("u")+"\",\""+sessionStorage.getItem("p")+"\","+params;
		request.get(str,(err,res,body)=>{
			console.log("str : "+str);
			 var parsed=JSON.parse(body);
			alert(parsed[0].result);
			window.location=config.serverAddress()+"/adh";
      		
		});
		 }
		
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			console.log(parsed);
      		this.setState({userRank:parsed[0].rank});
			this.getAdh();
		});
	}
	
	componentDidMount()
	{
	    this.getUserRights();
	}
	updateField(event)
	{
	//	let self=this;
		console.log("iddd :"+event.target.id);
		let r=this.state.data;
		var id=event.target.id;
		if(id==0) {r.nom=event.target.value;}
		else if(id==1) {r.nom_jeune_fille=event.target.value;}
		else if(id==2) {r.prenom=event.target.value;}
		else if(id==3) {r.naissance=event.target.value;}
		else if(id==4) {r.adresse=event.target.value;}
		else if(id==5) {r.adresse2=event.target.value;}
		else if(id==6) {r.code_postal=event.target.value;}
		else if(id==7) {r.ville=event.target.value;}
		else if(id==8) {r.tel=event.target.value;}
		else if(id==9) {r.mail=event.target.value;}
		else if(id==10) {r.T=event.target.value;}
		else if(id==11) {r.D=event.target.value;}
		else if(id==12) {r.M=event.target.value;}
		else if(id==13) {r.SC=event.target.value;}
		else if(id==14) {r.resp=event.target.value;}
		else if(id==15) {r.dest=event.target.value;}
		else if(id==16) {r.courrier=event.target.value;}
	this.setState({data:r});
	}
	
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			console.log("id :"+this.state.adhId);
			return (<div id="AdhUpdate"><form onSubmit={this.updateAdh}><br/>
			<div class='text'>Modification de fiche</div>
			<table>
			<tr>
			<td>Nom </td>
			<td><input id="0" type="text" onChange={this.updateField} value={this.state.data.nom}/></td>
			</tr><tr>
			<td>Nom Jeune Fille </td>
			<td><input id="1" type="text" onChange={this.updateField}  value={this.state.data.nom_jeune_fille}/></td>
			</tr><tr>
			<td>Prénom </td>
			<td><input id="2" type="text" onChange={this.updateField}  value={this.state.data.prenom}/></td>
			</tr><tr>
			<td>Date de Naissance </td>
			<td><input id="3" type="text" onChange={this.updateField}  value={this.state.data.naissance}/></td>
			</tr><tr>
			<td>Adresse </td>
			<td><input id="4" type="text" onChange={this.updateField}  value={this.state.data.adresse}/></td>
			</tr><tr>
			<td>Complément Adresse </td>
			<td><input id="5" type="text" onChange={this.updateField}  value={this.state.data.adresse2}/></td>
			</tr><tr>
			<td>Code Postal </td>
			<td><input id="6" type="text" onChange={this.updateField}  value={this.state.data.code_postal}/></td>
			</tr><tr>
			<td>Ville </td>
			<td><input id="7"  type="text" onChange={this.updateField} value={this.state.data.ville}/></td>
			</tr><tr>
			<td>Téléphone </td>
			<td><input id="8" type="text" onChange={this.updateField} value={this.state.data.tel} /></td>
			</tr><tr>
			<td>Courriel </td>
			<td><input id="9" type="text" onChange={this.updateField}  value={this.state.data.mail} /></td>
			 </tr>
			 <tr>
			<td>T </td>
			<td><input id="10" type="text" onChange={this.updateField}  value={this.state.data.T} /></td>
			 </tr>
			 <tr>
			<td>D </td>
			<td><input id="11" type="text" onChange={this.updateField}  value={this.state.data.D} /></td>
			 </tr>
			 <tr>
			<td>M </td>
			<td><input id="12" type="text" onChange={this.updateField}  value={this.state.data.M} /></td>
			 </tr>
			 <tr>
			<td>S.C </td>
			<td><input id="13" type="text" onChange={this.updateField}  value={this.state.data.SC} /></td>
			 </tr>
			 <tr>
			<td>Responsable </td>
			<td><input id="14" type="text" onChange={this.updateField}  value={this.state.data.resp} /></td>
			 </tr>
			 <tr>
			<td>Destination Cendres </td>
			<td><input id="15" type="text" onChange={this.updateField}  value={this.state.data.dest} /></td>
			 </tr>
			
			 
            </table>	
			<input type='button' value='Mettre à jour' onClick={()=>this.updateAdh()} />
			</form>
			</div>);
			
			
		}
			else return (<div>Attendre</div>);	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default AdhUpdate;