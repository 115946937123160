import React, { Component } from 'react';
 
import OldAdhList from './oldAdhList';
//const mySql=require('../mySql/sqlServer');

var config=require('../config');
var request=require('request');
var guiUtils=require('../ApsHtmlGuiUtils');

//var md5=require("md5");
//var sha256=require("crypto-js/sha256");
//var bcrypt=require('bcryptjs');
//var sql=require("../sqlServer");


class ArcAdhMan extends Component {
	constructor(props){
		super(props);
		this.state={userRank:''};
		this.getUserRights=this.getUserRights.bind(this);
		this.exportCsv=this.exportCsv.bind(this);
	
	}
	
    
	
	getUserRights()
	{
		let self=this;
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		
		fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				self.setState({userRank:data[0].rank});
				
			}).catch(err=>{console.log('caught',err);
			});		
			
	}
	
	componentDidMount()
	{
	    this.getUserRights();
	}
	
	exportCsv()
	{
		let dataEx=[];
		let str=config.sqlServerAddress()+'/gRaw/getOldAdhList/'
			
			+
			"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
			
			fetch(str,{
			method:'GET'}).then(function(response){
				if(response.status>=400){
					throw new Error("Bad resp");
				}
				return response.json();
			}).then(function(data){
				dataEx=data;
				let header=["id","NOM","NOM JEUNE FILLE","PRENOM","NAISSANCE","ADRESSE","COMP ADRESSE","CODE POSTAL","VILLE","TEL","email/règl. Cotis.","T","D","M","S.C.","RESPONSABLE TESTAMENT","DESTINATION CENDRES"];
				let str=guiUtils.jsonToString(data,header);
				console.log(str);
				var a=document.createElement("a");
				var file=new Blob([str],{type:'text/plain'});
				a.href=URL.createObjectURL(file);
				a.download="adherents.csv";
				a.click();
			//	self.forceUpdate();
			}).catch(err=>{console.log('caught',err);
			});
	}
	
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			
			return (<div id="adh">
			<br/>
			<OldAdhList title="Liste des Adhérents archivés" />
			<br/>
			<input type='button' value="Exporter Fichier CSV" onClick={()=>this.exportCsv()} />
			</div>);
			
		}
			else return <div>Attendre</div>;	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default ArcAdhMan;