import React, { Component } from 'react';
import DepositCsvUpload from './userMan/depositCsvUpload';

var config=require('./config');
var request=require('request');
var guiUtils=require('./ApsHtmlGuiUtils');




class ImportAdhDeposit extends Component {
	constructor(props){
		super(props);
		this.state={userRank:''};
		this.getUserRights=this.getUserRights.bind(this);
		
	}
	
	
	getUserRights()
	{
		let str=config.sqlServerAddress()+'/gRaw/getUserRights/'
		+"'"+sessionStorage.getItem("u")+"','"+sessionStorage.getItem("p")+"'";
		request.get(str,(err,res,body)=>{
			
			 var parsed=JSON.parse(body);
			this.setState({userRank:parsed[0].rank});
			
			
		});
	}
	
	componentDidMount()
	{
	    this.getUserRights();
	}
	
	
	
	
	render()
	{
		let rank=this.state.userRank;
		
		if(rank=="0")
		{
			return (<div> Rang = {rank}</div>);
		}
		else if(rank=="2")
		{
			return (<div id="ImportDeposit"><br/><div class='text'>Import Fichier Csv Dépôts
			</div><br/>
			<DepositCsvUpload />
			</div>);
			
		}
			else return (<div>Attendre</div>);	
		
		//ReactDom.render(<PartFinderButton />,document.getElementById('_partFinderButton'));
	}
}

export default ImportAdhDeposit;