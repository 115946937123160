import React, { Component } from 'react';


class MainHeader extends Component{
	constructor(props){
		super(props);
		this.addBlankLine=this.addBlankLine.bind(this);
	}
	addBlankLine()
	{
		return {__html:'<br>'};
	}
	render()
	{
		return (
		<div id="_MainHeader">
	   <div align='center'>
 <img class='logo' src='LOGO2.jpg'/>
	  
	   <br/>
	   <br/>Realtime Robot Control Apps
	   <br/>Embedded Development
	   <br/>Augmented Reality
	   <br/>AGI
	   <br/>
	   
	   <br/>Full Website is coming
	   <br/>In the meantime, you can get in touch with us at cyrille@i-machine.fr
	    <br/><br/>
		You can also take a look at our teasing videos <a href="https://www.youtube.com/channel/UC1ZlzaPdZr5Kwgpw5-ASJfw">there</a>			
		</div></div>	   
	   );
	   
	}
}

export default MainHeader;
