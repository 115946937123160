const _serverAddress="http://imachine.fr";
const serverIpV4="91.121.89.229";
const mysqlServerPort="8001";
const _sqlServerAddress="http://91.121.89.229:"+mysqlServerPort;
const mainDb="i_machine";
exports.serverAddress=function()
	{
		return _serverAddress;
	}
	exports.ipV4=function()
	{
		return serverIpV4;
	}
	exports.sqlServerAddress=function()
	{
		return _sqlServerAddress;
	}
	exports.sqlServerPort=function()
	{
		return mysqlServerPort;
	}
	exports.sqlServerMainDb=function()
	{
		return mainDb;
	}

