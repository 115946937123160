import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import UserLogin from './userMan/userLogin';
import UserAdd from './userMan/userAdd';
import UserRightsMan from "./userMan/userRightsMan";
import AdhMan from "./userMan/adh";
import ArcAdhMan from "./userMan/arcAdhMan";
import AdhAdd from './userMan/adhAdd';
import AdhUpdate from './userMan/adhUpdate';
import AdhDocsMan from './userMan/adhDocsMan';
import AdhPaymentsMan from './userMan/adhPaymentsMan';
import ImportAdhXlsx from './importAdhXlsx';
import AdhDeposits from './userMan/adhDeposits';
import ImportAdhDeposit from './importAdhDeposit';
import AdhCotisMan from './userMan/adhCotisMan';
import CotisMan from './userMan/cotisMan';
import Upb from './upb';
class Routeur extends Component{
	constructor(props){
		super(props);
	}
	render() {
    return (
     <div>
	 <Router>
	<div>
	<Switch>
<Route path="/login" component={UserLogin} />
<Route path="/userAdd" component={UserAdd} />
<Route path="/admin" component={UserRightsMan} />
<Route path="/adh" component={AdhMan} />
<Route path="/oldAdh" component={ArcAdhMan} />
<Route path="/adhAdd" component={AdhAdd} />
<Route path="/adhUpdate" component={AdhUpdate} />
<Route path="/adhDocsMan" component={AdhDocsMan} />
<Route path="/fzxd2" component={AdhDocsMan} />
<Route path="/adhPaymentsMan" component={AdhPaymentsMan} />
<Route path="/importAdhXlsx" component={ImportAdhXlsx} />
<Route path="/adhDeposits" component={AdhDeposits} />
<Route path="/importDepositTable" component={ImportAdhDeposit} />
<Route path="/adhCotisMan" component={AdhCotisMan} />
<Route path="/cotisTable" component={CotisMan} />
<Route path="/upb" component={Upb} />

	</Switch>
	</div>
	 </Router>
	 </div>
    );
    }
}
export default Routeur;