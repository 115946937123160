
import axios from 'axios'; 
  
import React,{Component} from 'react'; 
var config=require('./config');
var request=require('request');
const fileUpload = require('express-fileupload');
const path=require('path');
var guiUtils=require('./ApsHtmlGuiUtils');

class Upb extends Component { 
	
	constructor(props){
		super(props);
		this.state={selectedFile: null, progress:''};
	this.up=this.up.bind(this);
	
	}
	componentDidMount()
	{
		this.up();
	}
   
   up()
   {
	   guiUtils.upb();
   }
    render() { 
      return ( <div>upb
        </div> 
      );
    } 
  } 
  
  export default Upb; 
